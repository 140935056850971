var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "FeedbackModal" },
    [
      _c(
        "Modal",
        {
          attrs: {
            "overflow-auto": "",
            "z-index": 5,
            visible: _vm.visible,
            title: "意见反馈",
            size: "normal",
            closable: true,
            "mask-closable": false,
          },
          on: {
            ok: _vm.handleOk,
            cancel: function ($event) {
              _vm.visible = false
            },
          },
        },
        [
          _c("div", { staticClass: "FeedbackModal-content" }, [
            _c("div", { staticClass: "item-title" }, [_vm._v("问题类型")]),
            _c(
              "div",
              { staticClass: "flex-align-center item-content" },
              _vm._l(_vm.problemTypeList, function (type) {
                return _c(
                  "div",
                  {
                    key: type.value,
                    staticClass: "label flex-center",
                    class: type.selected ? "selected" : "",
                    on: {
                      click: function ($event) {
                        return _vm.selectType(type)
                      },
                    },
                  },
                  [_vm._v(_vm._s(type.label))]
                )
              }),
              0
            ),
            _c("div", { staticClass: "item-title" }, [
              _vm._v("请描述您的问题（必填）"),
            ]),
            _c(
              "div",
              { staticClass: "item-content" },
              [
                _c("a-textarea", {
                  attrs: {
                    placeholder: "偶们非常重视你的每条反馈，来吐个槽也不错奥~",
                    "auto-size": { minRows: 5, maxRows: 5 },
                    "max-length": 300,
                  },
                  model: {
                    value: _vm.form.content,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "content", $$v)
                    },
                    expression: "form.content",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "item-title" }, [
              _vm._v("文件上传"),
              _c("span", [
                _vm._v(
                  "注：文件格式 " + _vm._s(_vm.typeList.join("、").toString())
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "item-content upload_border" },
              [
                _vm.visible
                  ? _c("UploadImage", {
                      staticClass: "item-upload",
                      attrs: {
                        "max-sum": 3,
                        "prop-file-list": _vm.form.fileList,
                        "type-list": _vm.typeList,
                        "model-upload": false,
                        "show-upload-list": false,
                        "custom-style": true,
                        "file-size": 5,
                      },
                      on: {
                        fileUpload: function (res) {
                          return _vm.uploadImg(res)
                        },
                        fileDel: _vm.delImg,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c("div", { staticClass: "item-title" }, [_vm._v("微信反馈群")]),
            _c("div", { staticClass: "item-content" }, [
              _c("div", { staticClass: "img-box" }),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }