var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-image" },
    [
      _vm.uploadDragger
        ? _c(
            "a-upload-dragger",
            {
              staticClass: "avatar-uploader",
              attrs: {
                name: "avatar",
                "list-type": _vm.listType,
                disabled: _vm.disabled,
                "show-upload-list": _vm.showUploadList,
                "before-upload": _vm.beforeUpload,
                "file-list": _vm.fileList,
                multiple: _vm.multiple,
                "custom-request": _vm.customRequest,
              },
              on: { change: _vm.handleChange },
            },
            [
              _c("div", [
                _c(
                  "p",
                  [
                    _c("a-icon", {
                      style: { fontSize: "24px", marginRight: "5px" },
                      attrs: { type: _vm.loading ? "loading" : "upload" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ant-upload-text" },
                  [
                    _vm._t("tips", [
                      _c("p", { staticClass: "ant-upload-text" }, [
                        _vm._v(" 将简历拉至此处自动上传或浏览文件夹 "),
                      ]),
                      _c("p", { staticClass: "ant-upload-text" }, [
                        _vm._v(" 支持DOC、DOCX、PDF、JPG、PNG格式附件 "),
                      ]),
                    ]),
                  ],
                  2
                ),
              ]),
            ]
          )
        : _vm._e(),
      !_vm.uploadDragger
        ? _c(
            "a-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                name: "avatar",
                "list-type": _vm.listType,
                disabled: _vm.disabled,
                "show-upload-list": _vm.showUploadList,
                "before-upload": _vm.beforeUpload,
                "file-list": _vm.fileList,
                multiple: _vm.multiple,
                "custom-request": _vm.customRequest,
              },
              on: { change: _vm.handleChange },
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "ant-upload-text" },
                  [
                    _vm._t("tips", [
                      _c(
                        "a-button",
                        [
                          _c("a-icon", { attrs: { type: "upload" } }),
                          _vm._v("点击上传"),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ]),
              _vm.customStyle && _vm.fileList.length > 0
                ? [
                    _vm._t("fileList", [
                      _c(
                        "div",
                        _vm._l(_vm.fileList, function (item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "custom_box",
                              on: {
                                mouseenter: function ($event) {
                                  return _vm.enter(i)
                                },
                                mouseleave: function ($event) {
                                  return _vm.leave()
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "file_icon",
                                attrs: { src: _vm.defaultIcon(item), alt: "" },
                              }),
                              _vm.fileOimg(item)
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: item.url,
                                        target: "_blank",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(item.originFileName))]
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(item.originFileName)),
                                  ]),
                              _c("span", { staticClass: "mypor" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.seen && i == _vm.current,
                                        expression: "seen&& i==current",
                                      },
                                    ],
                                    staticClass: "index-show",
                                  },
                                  [
                                    _c("a-icon", {
                                      staticClass: "download-icon",
                                      attrs: { type: "download" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.handleDownload(item)
                                        },
                                      },
                                    }),
                                    _c("a-icon", {
                                      attrs: { type: "delete" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteFile(i)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.uploadDragger
        ? [
            _vm._t(
              "fileList",
              [
                _c(
                  "div",
                  { staticClass: "fileList" },
                  _vm._l(_vm.fileList, function (item, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "file",
                        on: {
                          mouseenter: function ($event) {
                            return _vm.enter(i)
                          },
                          mouseleave: function ($event) {
                            return _vm.leave()
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "file-icon",
                          attrs: { src: _vm.defaultIcon(item), alt: "" },
                        }),
                        _c("span", [_vm._v(_vm._s(item.originFileName))]),
                        _c("span", { staticClass: "mypor" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.seen && i == _vm.current,
                                  expression: "seen&& i==current",
                                },
                              ],
                              staticClass: "index-show",
                            },
                            [
                              _c("a-icon", {
                                staticClass: "download-icon",
                                attrs: { type: "download" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDownload(item)
                                  },
                                },
                              }),
                              _c("a-icon", {
                                attrs: { type: "delete" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFile(i)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ],
              { fileList: _vm.fileList }
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }