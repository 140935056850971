<template>
  <div class="FeedbackModal">
    <Modal overflow-auto :z-index="5" :visible="visible" :title="'意见反馈'" :size="'normal'" :closable="true" :mask-closable="false" @ok="handleOk" @cancel="visible=false">
      <div class="FeedbackModal-content">
        <div class="item-title">问题类型</div>
        <div class="flex-align-center item-content">
          <div
            v-for="type in problemTypeList"
            :key="type.value"
            :class="type.selected?'selected':''"
            class="label flex-center"
            @click="selectType(type)"
          >{{ type.label }}</div>
        </div>
        <div class="item-title">请描述您的问题（必填）</div>
        <div class="item-content">
          <a-textarea
            v-model="form.content"
            placeholder="偶们非常重视你的每条反馈，来吐个槽也不错奥~"
            :auto-size="{ minRows: 5, maxRows: 5 }"
            :max-length="300"
          />
        </div>
        <div class="item-title">文件上传<span>注：文件格式 {{ typeList.join('、').toString() }}</span></div>
        <div class="item-content upload_border">
          <UploadImage
            v-if="visible"
            :max-sum="3"
            :prop-file-list="form.fileList"
            :type-list="typeList"
            :model-upload="false"
            :show-upload-list="false"
            :custom-style="true"
            :file-size="5"
            class="item-upload"
            @fileUpload="(res) => uploadImg(res)"
            @fileDel="delImg"
          />
        </div>
        <div class="item-title">微信反馈群</div>
        <div class="item-content">
          <div class="img-box">
            <!-- <img src="" alt=""> -->
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { createFeedBack } from '@/services/home/index.js'
import UploadImage from '@/components/UploadFile/upload.vue'
export default {
  name: 'FeedbackModal',
  components: {
    UploadImage,
    Modal: () => import('@/components/CbModal/index')
  },
  data() {
    return {
      visible: false,
      // function:功能建议 content:内容建议 exception:异常反馈 bug:BUG反馈 other:其他
      problemTypeList: [
        { label: '功能建议', value: 'function', selected: true },
        { label: '内容建议', value: 'content', selected: false },
        { label: '异常反馈', value: 'exception', selected: false },
        { label: 'BUG反馈', value: 'bug', selected: false },
        { label: '其 他', value: 'other', selected: false }
      ],
      form: {
        feedBackType: 'function',
        content: '',
        fileList: []
      },
      // , 'xlsx', 'xls', 'doc', 'docx'
      typeList: ['jpeg', 'png', 'jpg', 'pdf']
    }
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.form.feedBackType = 'function'
        this.form.content = ''
        this.form.fileList = []
      }
    }
  },
  methods: {
    open() {
      this.visible = true
    },
    async handleOk() {
      await createFeedBack(this.form)
      this.$message.success('提交成功！感谢您的反馈。')
      this.visible = false
    },
    beforeUpload(file) {
      const acceptList = ['png', 'jepg', 'jpg', 'docx', 'doc', 'pdf']
      const fileType = file.name.split('.').pop()
      const isXlsx = acceptList.includes(fileType)
      if (!isXlsx) {
        this.$message.error('文件类型错误！只支持png/jepg/jpg/Word/pdf文件！')
      }
      const isLt20M = file.size / 1024 / 1024 < 5
      if (!isLt20M) {
        this.$message.error('文件大小不能超过5M！')
      }
      return isXlsx && isLt20M
    },
    handleChange(data) {
      // 删除文件
      if (data.file.status === 'removed') {
        this.form.fileList = data.fileList
      }
    },
    // 上传图片
    uploadImg(file) {
      this.form.fileList = file.map(item => {
        return {
          fileId: item.fileId,
          fileName: item.originFileName,
          ...item
        }
      })
    },
    delImg({ file, list }) {
      this.form.fileList = this.form.fileList.filter(item => {
        return item.fileId !== file.fileId
      })
    },
    selectType(type) {
      this.problemTypeList.forEach(v => {
        v.selected = false
      })
      this.form.feedBackType = type.value
      type.selected = true
    }
  }
}
</script>

<style lang="less" scoped>
.FeedbackModal {
  &-content{
  .item-title{
    color: @sc-grey-60;
    .font-size(14px);
    span{
      margin-left:15px;
    }
  }
  .item-content {
    margin: 10px 0 20px 0;
    .img-box{
      width: 83px;
      height: 83px;
      border-radius: 4px;
      border: 1px solid @sc-greyBg-10;
      img{
        width:100%;
        height:100%
      }
    }
  }
  .label{
    cursor: pointer;
    margin-right:12px;
    width: 92px;
    height: 32px;
    background: @sc-greyBg-10;
    border-radius: 4px;
    &:hover {
      color: @sc-primary-100;
    }
  }
  .label:last-child{
    margin-right: 0;
  }
  .selected{
    border:1px solid @sc-primary-100;
    color:@sc-primary-100;
    background: #fff;
  }
  }
}
</style>
