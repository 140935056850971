// 首页
import { GLOBAL_SETTING, MESSAGE_SETTING } from '../api'
import { METHOD, request } from '@/utils/request'

// 追加意见反馈
export async function createFeedBack(params) {
  return request(`${GLOBAL_SETTING}/feedBack/createFeedBack`, METHOD.POST, params)
}

// 查询管理员站内信消息
export async function getUserPlatformMessageList(params) {
  return request(`${MESSAGE_SETTING}/notification/getUserPlatformMessageList`, METHOD.GET, params)
}

// 站内信消息已读
export async function alreadyReadMessage(params) {
  return request(`${MESSAGE_SETTING}/notification/alreadyReadMessage`, METHOD.GET, params)
}
