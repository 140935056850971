<template>
  <div class="upload-image">
    <a-upload-dragger
      v-if="uploadDragger"
      name="avatar"
      :list-type="listType"
      class="avatar-uploader"
      :disabled="disabled"
      :show-upload-list="showUploadList"
      :before-upload="beforeUpload"
      :file-list="fileList"
      :multiple="multiple"
      :custom-request="customRequest"
      @change="handleChange"
    >
      <!-- <img v-if="imageUrl" :src="imageUrl" alt="avatar"> -->
      <div>
        <p>
          <a-icon
            :type="loading ? 'loading' : 'upload'"
            :style="{ fontSize: '24px', marginRight: '5px' }"
          />
        </p>
        <div class="ant-upload-text">
          <slot name="tips">
            <p class="ant-upload-text">
              将简历拉至此处自动上传或浏览文件夹
            </p>
            <p class="ant-upload-text">
              支持DOC、DOCX、PDF、JPG、PNG格式附件
            </p>
          </slot>
        </div>
      </div>
    </a-upload-dragger>
    <a-upload
      v-if="!uploadDragger"
      name="avatar"
      :list-type="listType"
      class="avatar-uploader"
      :disabled="disabled"
      :show-upload-list="showUploadList"
      :before-upload="beforeUpload"
      :file-list="fileList"
      :multiple="multiple"
      :custom-request="customRequest"
      @change="handleChange"
    >
      <!-- <img v-if="imageUrl" :src="imageUrl" alt="avatar"> -->
      <div>
        <div class="ant-upload-text">
          <slot name="tips">
            <a-button><a-icon type="upload" />点击上传</a-button>
          </slot>
        </div>
      </div>
      <template v-if="customStyle && fileList.length > 0">
        <slot name="fileList">
          <div>
            <div v-for="(item,i) in fileList" :key="i" class="custom_box" @mouseenter="enter(i)" @mouseleave="leave()">
              <img :src="defaultIcon(item)" alt="" class="file_icon">
              <a v-if="fileOimg(item)" :href="item.url" target="_blank" @click.stop>{{ item.originFileName }}</a>
              <span v-else>{{ item.originFileName }}</span>
              <span class="mypor">
                <div v-show="seen&& i==current" class="index-show">
                  <!-- <a-icon v-if="item" type="eye" @click="previewImg(item)" /> -->
                  <a-icon type="download" class="download-icon" @click.stop="handleDownload(item)" />
                  <a-icon type="delete" @click.stop="deleteFile(i)" />
                </div>
              </span>
            </div>
          </div>
        </slot>
      </template>
    </a-upload>
    <template v-if="uploadDragger">
      <slot name="fileList" :fileList="fileList">
        <div class="fileList">
          <div v-for="(item,i) in fileList" :key="i" class="file" @mouseenter="enter(i)" @mouseleave="leave()">
            <img :src="defaultIcon(item)" alt="" class="file-icon">
            <span>{{ item.originFileName }}</span>
            <!-- <iframe ref="mainiframe" :src="item.url" style="height:100vh;width:100%" /> -->
            <span class="mypor">
              <div v-show="seen&& i==current" class="index-show">
                <a-icon type="download" class="download-icon" @click="handleDownload(item)" />
                <a-icon type="delete" @click="deleteFile(i)" />
              </div>
            </span>
          </div>
        </div>
      </slot>
    </template>
  </div>
</template>

<script>
import ImageCompressor from 'image-compressor.js' // 引入
import { upload, getSignature } from '@/services/upload/index.js'
import { IMG_PREVIEW } from '@/services/api.js'
// import pdf from 'vue-pdf'
/**
 * typeList<Array>
 */
export default {
  name: 'CbUpload',
  components: {
    // pdf
  },
  model: {
    prop: 'propFileList',
    event: 'change'
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    listType: {
      type: String,
      default: 'picture'
    },
    showUploadList: {
      type: Boolean,
      default: true
    },
    uploadDragger: {
      type: Boolean,
      default: false
    },
    // 文件类型列表
    typeList: {
      default: () => ['*'],
      type: Array
    },
    // 大小 单位M
    fileSize: {
      default: 2,
      type: Number
    },
    // 上传的文案
    uplaodTip: {
      default: '上传附件',
      type: String
    },
    // 最大上传数量
    maxSum: {
      default: 2,
      type: Number
    },
    // 文件是否压缩
    compress: {
      default: true,
      type: Boolean
    },
    // 文件列表
    propFileList: {
      type: Array,
      default: () => []
    },
    // 下面为业务属性
    // 文件类型,头像为avatar,其他文件为other
    fileType: {
      default: 'other',
      type: String,
      require: true
    },
    // 0 不敏感 1敏感
    sensitiveFile: {
      default: '1',
      type: String,
      require: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showDel: {
      type: Boolean,
      default: true
    },
    modelUpload: {
      type: Boolean,
      default: true
    },
    // 自定义图片展示插槽
    customStyle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      seen: false,
      current: 0,
      basePath: process.env.VUE_APP_API_BASE_URL,
      imageUrl: '',
      fileList: [],
      loading: false,
      index: -1,
      pageCount: 0,
      currentPage: 0,
      previewVisible: false, // 预览弹窗显示
      previewImage: '' // 预览图片路径
    }
  },
  computed: {

  },
  watch: {
    fileList(newVal) {
      if (newVal.length > 0) {
        newVal[0].name = newVal[0].name ? newVal[0].name : '-'
        this.$emit('change', newVal)
      }
    },
    propFileList(newVal) {
      const targetVal = newVal.map((item, index) => {
        return {
          ...item,
          uid: index, // 注意，这个uid一定不能少，否则上传失败
          // name: item.fileName,
          status: 'done',
          url: item.fileUrl,
          percent: 100
        }
      })
      // 防止死循环
      if (newVal.length === 0 || newVal[newVal.length - 1].status) return
      this.$set(this, 'fileList', targetVal)
      // if (this.fileList.length > 0) {
      //   this.index = 0
      // }
    }
  },
  methods: {
    enter(index) {
      this.seen = true
      this.current = index
    },
    leave() {
      this.seen = false
      this.current = null
    },
    defaultIcon(item) {
      const name = item.name.toLowerCase().split('.')
      if (['doc', 'docx'].includes(name[1])) {
        return require('@/assets/img/word_icon.png')
      } else if (['pdf'].includes(name[1])) {
        return require('@/assets/img/pdf_icon.png')
      } else if (['xlsx', 'xls'].includes(name[1])) {
        return require('@/assets/img/xlsx.png')
      }
      return item.url
    },
    // 判断是文件还是图片
    fileOimg(item) {
      const name = item.name.toLowerCase().split('.')
      if (['doc', 'docx', 'pdf', 'xlsx', 'xls'].includes(name[1])) {
        return false
      } else if (['jpeg', 'png', 'jpg'].includes(name[1])) {
        return true
      }
    },
    handleChange({ file, fileList }) {
      // 删除文件
      if (file.status && file.status === 'removed') {
        this.fileList = fileList
        // this.index = fileList.length - 1
        this.$emit('fileDel', { file, fileList })
      }
    },
    // 上传前验证
    beforeUpload({ name, size }) {
      return new Promise((resolve, reject) => {
        // 判断类型
        const { typeList, fileSize } = this
        if (!typeList.includes('*')) {
          const singleType = name.toLowerCase().split('.')
          const typeInclude = typeList.includes(singleType[singleType.length - 1])
          if (!typeInclude) {
            this.$message.error('上传文件格式错误, 请重新选择')
            return reject(false)
          }
        }
        // 判断大小
        const isLt2M = size / 1024 / 1024 < fileSize
        if (!isLt2M) {
          this.$message.error(`文件最大上传${fileSize}M`)
          return reject(false)
        }
        resolve(true)
      })
    },
    // 自定义上传的方法
    async customRequest(file) {
      // 设置文件的 realIndex 值
      // 该值用来计算 文件上传的相关信息
      const realIndex = this.fileList.length + 1
      // 判断是否超出数量
      if (realIndex > this.maxSum) {
        this.$message.error(`最多上传${this.maxSum}个`)
        // this.index--
        return
      }
      const imgItem = {
        uid: realIndex, // 注意，这个uid一定不能少，否则上传失败
        name: '',
        status: 'uploading',
        url: '',
        percent: 0 // 注意不要写100。100表示上传完成
      }
      this.fileList.push(imgItem)
      const form = new FormData()
      const realindex = this.fileList.length - 1
      form.append('file', file.file)
      form.append('fileName', file.file.name)
      form.append('fileType', this.fileType)
      form.append('sensitiveFile', this.sensitiveFile)
      // 请求上传文件
      const res = await upload(form)
      if (res.code === 100) {
        let url = ''
        const rimgItem = {
          ...imgItem
        }
        if (this.sensitiveFile === '1') {
          const signatureRes = await getSignature({
            id: res.data.fileId
          })
          if (signatureRes.code === 100) {
            url = `${IMG_PREVIEW}/img?id=${res.data.fileId}&signature=${signatureRes.data.signature}&expireTime=${signatureRes.data.time}`
          }
          rimgItem.signature = signatureRes.data.signature
          rimgItem.time = signatureRes.data.time
        } else {
          url = `${IMG_PREVIEW}/img?id=${res.data.fileId}`
        }
        rimgItem.name = res.data.fileName
        rimgItem.originFileName = res.data.originFileName
        rimgItem.fileId = res.data.fileId
        rimgItem.staffFileId = res.data.staffFileId
        rimgItem.url = url
        rimgItem.status = 'done'
        // this.$set(this.fileList, (realindex), rimgItem)
        this.fileList.splice(realindex, 1, rimgItem)
        this.$emit('fileUpload', this.fileList)
      }
    },
    // 压缩文件
    compressHandle(file) {
      return new Promise((resolve, reject) => {
        new ImageCompressor(file, {
          quality: 0.6,
          success(result) {
            return resolve(result)
          },
          error(e) {
            reject(e)
          }
        })
      })
    },
    // 单独预览
    previewImg(url) {
      this.previewImage = url
      this.previewVisible = true
    },
    deleteFile(i) {
      this.fileList.splice(i, 1)
      console.log(i, this.fileList, 'ppp')
      this.$emit('fileUpload', this.fileList)
    },
    // 单独删除
    handleSingleDel(file) {
      if (this.modelUpload) {
        this.$Reconfirm({
          title: '提示',
          content: `确认删除${file.name}吗？`
        }).then(() => {
          this.fileList = []
          this.index = this.fileList.length - 1
          this.$emit('fileDel', { file })
        }).catch(() => {
          console.log('cancel')
        })
      } else {
        this.fileList = []
        this.index = this.fileList.length - 1
        this.$emit('fileDel', { file })
      }
    },
    // 单独下载
    handleDownload(file) {
      window.location.href = `${this.basePath}/hrsaas-storage/file?id=${file.fileId}&signature=${file.signature}&expireTime=${file.time}`
    }
  }
}
</script>

<style lang="less" scoped>
  .custom_box{
    position: relative;
    width: 500px;
    margin-top:16px;
    padding:8px;
    border:1px solid @sc-greyBg-20;
    border-radius: 4px;
    // height: 50px;
    .file_icon{
      width: 48px;
      height: 48px;
    }
    a, span{
      margin-left: 16px;
    }
    .mypor{
      position:absolute;
      right:10px;
      top:20px;
      .download-icon{
        margin-right:16px;
      }
    }
  }
.upload-image {
  position: relative;
  .fileList{
    margin-top:20px;
  }
  .file{
    margin-bottom:16px;
    padding:8px;
    border:1px solid @sc-greyBg-20;
    border-radius: 4px;
    position: relative;
    .mypor{
      position:absolute;
      right:10px;
      top:24px;
      .download-icon{
        margin-right:16px;
      }
    }
    cursor: pointer;
    .file-icon{
      width:48px;
      height:48px;
      margin-right:16px;
    }
    &:hover{
      background: @sc-greyBg-10;
    }
  }
  .upload-file-contain {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
      top: 0;
      left: 0;
    .flex-center();
    flex-direction: column;
      padding: 8px;
    .upload-file {
      width: 34px !important;
      height: 39px !important;
    }
    .upload-file-image{
      max-width: calc(307px - 16px);
      // width: 100%;
      height: 100%;
    }
    .upload-title {
      .font-size(14px);
      color: @sc-grey-60;
      margin-top: 9px;
      max-width: 280px;
      .multi-ellipsis(1);
    }
    .upload-file-actions-p {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(32, 42, 64, 0.5);
      opacity: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      .upload-file-actions {
        text-align: right;
        .anticon {
          font-size: 16px;
          vertical-align: bottom;
          vertical-align: -webkit-baseline-middle;
          color: #fff;
          padding: 0 6px;
        }
      }
    }
    &:hover .upload-file-actions-p,
    &:hover .upload-file-actions {
      opacity: 1;
    }
  }
  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    // margin-top: 8px;
    color: #666;
  }
  /deep/.ant-upload.ant-upload-select-picture-card{
    width:100%;
  }
}

</style>
